import { useRef, useEffect, useMemo, useCallback, useReducer } from 'react';

import SwiperCore, { Pagination } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useRouter } from 'next/router';
import 'swiper/swiper.min.css';

import { determineSlideCountOnMobileView, determineSlideCountOnTabletView } from 'src/utils/carouselUtils';
import { mapRecommendationsToAnalyticsInput } from 'src/utils/mapRecommendationsToAnalyticsInput';
import { createNextLinkProps } from 'src/utils/createNextLinkProps';

import { breakpointsKeys } from 'src/enums/breakpoints';
import { SkeletonLoader } from './SkeletonLoader';

import GET_RECOMMENDATION_BY_QUERY from 'src/graphql/mutations/getRecommendationByQuery.gql';
import GET_RECOMMENDATION_BY_PARAM from 'src/graphql/mutations/getRecommendationByParam.gql';

import useBreakpointDetector from 'src/hooks/useBreakpointDetector';
import useRESTMutation from 'src/hooks/useRESTMutation';
import useAnalytics from 'src/hooks/useAnalytics';
import useApiHit from 'src/hooks/useApiHit';

import { initialState, reducer, ACTIONS, init } from 'src/reducers/carouselOfProductsFromQueryReducer';

import * as S from './styles';

// install Swiper modules
SwiperCore.use([Pagination]);

const defaultProps = {
  shuffle: false,
  limit: 5,
  salt: '',
  vertical: false,
  query: null,
  recommendationKey: null,
  rawItems: [],
  beforeChildren: null,
  minHeightTile: undefined,
  where: '',
  homePageForUserLayout: '',
  marginTop: 0,
  marginBottom: 0,
  displayHr: false,
};

const MOBILE_MAX_ITEMS_OFFSET = 3;
const DEBOUNCE_TIMEOUT = 300;
const verticalSpaceBetweenSlide = 30;
const horizontalSpaceBetweenSlide = 6;
const horizontalSpaceBetweenSlideOnListing = 8;

export const CarouselOfProductsFromQuery = ({
  query,
  recommendationKey,
  rawItems,
  shuffle,
  limit,
  productPageSlug,
  productPageLayout,
  salt,
  vertical,
  onnTopHomePageLayout,
  onnTopHomePageSlug,
  userName,
  userEmail,
  beforeChildren,
  hideOnnTop,
  minHeightTile,
  homePageForUserLayout,
  marginBottom,
  marginTop,
  where,
  className,
  hideStockValue,
  fromListing = false,
} = defaultProps) => {
  const shouldUseQuery = !!query;
  const shouldUseParam = !!recommendationKey;
  const isWithLocalItems = rawItems.length > 0;

  const swiperRef = useRef(null);
  const [state, dispatch] = useReducer(reducer, initialState, init(!isWithLocalItems));

  const router = useRouter();
  const productId = router.query.productId;
  const isHomePageForUser = router.route.substring(1) === homePageForUserLayout;

  const recommendationQuery = shouldUseParam ? GET_RECOMMENDATION_BY_PARAM : GET_RECOMMENDATION_BY_QUERY;

  const [getRecommendationPaged, { error: recommendationError }] = useRESTMutation(recommendationQuery, {
    ssr: false,
  });

  const saltRef = useRef(salt);
  if (saltRef.current === '') {
    saltRef.current = Number(new Date()).toString();
  }

  const currentBreakPoint = useBreakpointDetector();
  const isMobileView = currentBreakPoint === breakpointsKeys.MOBILE;
  const isTabletView = currentBreakPoint === breakpointsKeys.TABLET;

  const shouldDisplayVerticalOnTablet = isHomePageForUser && where === 'right' && isTabletView;

  const baseQueryVariables = useMemo(() => {
    const baseVariables = {
      shuffle: Number(shuffle),
      salt: saltRef.current,
      limit,
    };

    if (shouldUseQuery) {
      baseVariables.query = query;
    } else if (shouldUseParam) {
      baseVariables.param = recommendationKey;
    }

    return baseVariables;
  }, [limit, query, recommendationKey, shouldUseParam, shouldUseQuery, shuffle]);

  const { hit } = useApiHit();

  const isLessProductThanLimit = state.totalProducts === null ? null : state.totalProducts <= limit;

  const determineSlideCount = () => {
    if (isMobileView) {
      return determineSlideCountOnMobileView(state.totalProducts);
    }
    if (isTabletView && !shouldDisplayVerticalOnTablet) {
      return determineSlideCountOnTabletView(state.totalProducts);
    }
    if (fromListing) {
      return limit;
    }

    if (isLessProductThanLimit) {
      return state.totalProducts;
    }
    return limit;
  };

  const howManySlideShow = determineSlideCount();

  let showBeforeArrow = null;
  let showAfterArrow = null;
  if (
    state.totalProducts <= howManySlideShow ||
    isMobileView ||
    Boolean(isTabletView && !shouldDisplayVerticalOnTablet)
  ) {
    showBeforeArrow = false;
    showAfterArrow = false;
  } else {
    showBeforeArrow = !state.isOnFirstSlide;
    showAfterArrow = !state.isOnLastSlide;
  }

  const checkIfShouldBeDisplayedInHorizontal = () => {
    if (shouldDisplayVerticalOnTablet) return false;
    if (isMobileView || isTabletView || !vertical) return true;
  };

  const isHorizontalDirection = checkIfShouldBeDisplayedInHorizontal();

  const determineSpaceBetweenSlide = () => {
    if (isHorizontalDirection) {
      return horizontalSpaceBetweenSlide;
    }
    if (fromListing) {
      return horizontalSpaceBetweenSlideOnListing;
    }
    return verticalSpaceBetweenSlide;
  };
  const spaceBetweenSlide = determineSpaceBetweenSlide();

  const goToNextSlide = () => {
    if (swiperRef.current !== null) {
      swiperRef.current.slideNext();
    }
  };

  const goToPrevSlide = () => {
    if (swiperRef.current !== null) {
      swiperRef.current.slidePrev();
    }
  };

  const getNextPageAndAddSlide = useCallback(async () => {
    const { data: recommendationData } = await getRecommendationPaged({
      variables: { ...baseQueryVariables, page: state.currentPage + 1 },
    });

    const recommendations = recommendationData?.recommendation?.items || [];

    dispatch({ type: ACTIONS.GET_NEXT_SLIDES, payload: { recommendations } });
  }, [baseQueryVariables, getRecommendationPaged, state.currentPage]);

  const { addAnalyticsOnProductList } = useAnalytics();

  const reportImpressionViewFromCarousel = (products) => {
    const recommendationsAsProductsForAnalytics = products.map(mapRecommendationsToAnalyticsInput);
    addAnalyticsOnProductList(recommendationsAsProductsForAnalytics);
  };

  useEffect(() => {
    const getFirstData = async () => {
      const { data: recommendationData } = await getRecommendationPaged({
        variables: { ...baseQueryVariables, page: state.currentPage },
      });

      const recommendations = recommendationData?.recommendation?.items || [];
      const totalProducts = recommendationData?.recommendation?.total || null;

      if (recommendations?.length) {
        reportImpressionViewFromCarousel(recommendations);
      }

      dispatch({ type: ACTIONS.GET_INITIAL_SLIDES, payload: { recommendations, totalProducts } });
    };

    if (query || recommendationKey) {
      getFirstData();
    } else if (isWithLocalItems) {
      if (rawItems?.length) {
        reportImpressionViewFromCarousel(rawItems);
      }
      dispatch({ type: ACTIONS.GET_SLIDES_FROM_RAW_ITEMS, payload: { rawItems } });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recommendationKey, productId]);

  useEffect(() => {
    const currentPageValue = state.currentPage + 1;
    const maxItems = limit * currentPageValue;

    const debounce = setTimeout(() => {
      const shouldTriggerFetchMoreItems =
        state.activeIndex >= maxItems - MOBILE_MAX_ITEMS_OFFSET && !state.isFetchingMoreItems;

      if (shouldTriggerFetchMoreItems) {
        dispatch({ type: ACTIONS.SET_FETCH_MORE_TO_TRUE });
        fetchMoreItemsHandler();
      }
    }, DEBOUNCE_TIMEOUT);

    return () => clearTimeout(debounce);

    async function fetchMoreItemsHandler() {
      await getNextPageAndAddSlide();

      dispatch({ type: ACTIONS.SET_FETCH_MORE_TO_FALSE });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.currentPage, state.activeIndex, limit, state.isFetchingMoreItems]);

  if (recommendationError) {
    return null;
  }

  if (!query && !recommendationKey && !isWithLocalItems) {
    return null;
  }

  if (state.isLoading && !isHorizontalDirection) {
    return (
      <S.SkeletonsLoaderWrapperVertical>
        {Array.from({ length: Math.round(howManySlideShow) }, (_, index) => {
          return <SkeletonLoader key={index} />;
        })}
      </S.SkeletonsLoaderWrapperVertical>
    );
  }

  if (state.isLoading) {
    return (
      <S.SkeletonGroupWrapper>
        <S.SkeletonsLoaderWrapper fromListing={fromListing}>
          {Array.from({ length: Math.round(howManySlideShow) }, (_, index) => {
            return <SkeletonLoader key={index} />;
          })}
        </S.SkeletonsLoaderWrapper>
      </S.SkeletonGroupWrapper>
    );
  }

  if (state.slides.length < 1) {
    return null;
  }

  return (
    <>
      {beforeChildren}
      <S.CarouselOfProductsFromQuery
        verticalDirection={!isHorizontalDirection}
        shouldDisplayVerticalOnTablet={shouldDisplayVerticalOnTablet}
        marginBottom={marginBottom}
        marginTop={marginTop}
        className={className}
        fromListing={fromListing}
      >
        {showBeforeArrow ? (
          <S.ArrowBig
            direction={isHorizontalDirection ? 'left' : 'up'}
            onClick={() => {
              goToPrevSlide();
            }}
            large={isHorizontalDirection}
          />
        ) : null}
        <S.CarouselWrapper
          slideToShow={howManySlideShow}
          spaceBetweenSlide={spaceBetweenSlide}
          verticalDirection={!isHorizontalDirection}
          fromListing={fromListing}
        >
          <Swiper
            spaceBetween={spaceBetweenSlide}
            freeMode={isMobileView || isTabletView}
            slidesPerView={howManySlideShow}
            slidesPerGroup={isHorizontalDirection ? howManySlideShow : 1}
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
            }}
            onActiveIndexChange={(swiper) => {
              const slideIndex = swiper?.activeIndex;
              dispatch({ type: ACTIONS.SET_ACTIVE_INDEX, payload: { activeIndex: slideIndex } });

              if (slideIndex === 0 && !state.isOnFirstSlide) {
                dispatch({ type: ACTIONS.SET_IS_ON_FIRST_SLIDE, payload: true });
              }

              if (slideIndex > 0 && state.isOnFirstSlide) {
                dispatch({ type: ACTIONS.SET_IS_ON_FIRST_SLIDE, payload: false });
              }

              if (
                state.totalProducts !== null &&
                slideIndex === state.totalProducts - howManySlideShow &&
                !state.isOnLastSlide
              ) {
                dispatch({ type: ACTIONS.SET_IS_ON_LAST_SLIDE, payload: true });
              }

              if (
                state.totalProducts !== null &&
                slideIndex < state.totalProducts - howManySlideShow &&
                state.isOnLastSlide
              ) {
                dispatch({ type: ACTIONS.SET_IS_ON_LAST_SLIDE, payload: false });
              }
            }}
            direction={isHorizontalDirection ? 'horizontal' : 'vertical'}
          >
            {state.slides.map(
              (
                {
                  slug,
                  name,
                  imgUrl,
                  largeImgUrl,
                  productId,
                  isFullPackages,
                  nowInBasket,
                  isPartial,
                  stepQuantityProduct,
                  categoryName,
                  onninenIndex,
                  priceSign,
                  onntop,
                  onntopcb,
                  pricingValueVariants,
                  producerName,
                  availability,
                  rid,
                },
                index,
              ) => (
                <SwiperSlide key={`${name}_${onninenIndex}_${index}`}>
                  <S.ProductTile
                    name={name}
                    fromListing={fromListing}
                    fromCarousel
                    readMoreLinkProps={createNextLinkProps(`/${productPageSlug}/${slug}`, productPageLayout)}
                    imgUrl={imgUrl}
                    largeImgUrl={largeImgUrl}
                    pricingValueVariants={pricingValueVariants}
                    priceSign={priceSign}
                    stock={{
                      value: availability.mainVolume,
                      unit: availability.unit,
                      desc: availability?.conditionLabel || null,
                    }}
                    isFullPackages={isFullPackages}
                    nowInBasket={nowInBasket}
                    isPartial={isPartial}
                    productId={productId}
                    stepQuantityProduct={stepQuantityProduct}
                    categoryName={categoryName}
                    sku={onninenIndex}
                    producerName={producerName}
                    onntop={onntop}
                    onntopcb={onntopcb}
                    onnTopHomePageLayout={onnTopHomePageLayout}
                    onnTopHomePageSlug={onnTopHomePageSlug}
                    userName={userName}
                    userEmail={userEmail}
                    showAskForm={availability?.showAskForm || false}
                    hideStockValue={hideStockValue}
                    delayLargeImg={1000}
                    onClick={() => {
                      if (rid) {
                        hit({ rid, productid: productId });
                      }
                    }}
                    minHeight={minHeightTile}
                    hideOnnTop={hideOnnTop}
                  />
                </SwiperSlide>
              ),
            )}

            {state.slides.length < state.totalProducts && Boolean(isMobileView || isTabletView) && (
              <SwiperSlide>
                <SkeletonLoader />
              </SwiperSlide>
            )}
          </Swiper>
        </S.CarouselWrapper>
        {showAfterArrow ? (
          <S.ArrowBig
            direction={isHorizontalDirection ? 'right' : 'down'}
            onClick={async () => {
              if (swiperRef.current) {
                const currentIdxSlide = swiperRef.current.activeIndex + 1;
                const nextIdxSlide = currentIdxSlide + 1;
                const nextIdxVisibleSlide = nextIdxSlide + howManySlideShow - 1;
                const totalCurrentSlides = swiperRef.current.slides.length;
                const isNotAllPageDownload = totalCurrentSlides < state.totalProducts;
                const isThereNoMoreSlides = nextIdxVisibleSlide >= totalCurrentSlides;

                if (isNotAllPageDownload && isThereNoMoreSlides) {
                  await getNextPageAndAddSlide();
                }
              }
              goToNextSlide();
            }}
            arrowSize="verySmall"
            large={isHorizontalDirection}
          />
        ) : null}
      </S.CarouselOfProductsFromQuery>
    </>
  );
};

CarouselOfProductsFromQuery.defaultProps = defaultProps;
