const DISPLAY_DYNAMIC_ITEM_COUNT_MOBILE_VALUE = 2.5;
const DISPLAY_FIXED_ITEM_COUNT_MOBILE_VALUE = 3;

export const determineSlideCountOnMobileView = (totalProducts) => {
  if (totalProducts && totalProducts === DISPLAY_FIXED_ITEM_COUNT_MOBILE_VALUE) {
    // by requirements https://jira.unity.pl/browse/ONNINB2B-1146?focusedCommentId=1406934
    return DISPLAY_FIXED_ITEM_COUNT_MOBILE_VALUE;
  }

  // by requirements https://jira.unity.pl/browse/ONNINB2B-606
  return DISPLAY_DYNAMIC_ITEM_COUNT_MOBILE_VALUE;
};

const DISPLAY_DYNAMIC_ITEM_COUNT_TABLET_VALUE = 3.5;
const DISPLAY_FIXED_ITEM_COUNT_TABLET_VALUE = 4;

export const determineSlideCountOnTabletView = (totalProducts) => {
  if (totalProducts && totalProducts === DISPLAY_FIXED_ITEM_COUNT_TABLET_VALUE) {
    return DISPLAY_FIXED_ITEM_COUNT_TABLET_VALUE;
  }

  return DISPLAY_DYNAMIC_ITEM_COUNT_TABLET_VALUE;
};
