import { mapRecommendationsToOfferCarousel } from 'src/utils/mapRecommendationsToOfferCarousel';

export const ACTIONS = {
  GET_INITIAL_SLIDES: 'getInitialSlides',
  GET_SLIDES_FROM_RAW_ITEMS: 'getSlidesFromRawItems',
  GET_NEXT_SLIDES: 'getNextSlides',
  SET_ACTIVE_INDEX: 'setActiveIndex',
  SET_FETCH_MORE_TO_TRUE: 'setCanFetchMoreToTrue',
  SET_FETCH_MORE_TO_FALSE: 'setCanFetchMoreToFalse',
  SET_IS_ON_FIRST_SLIDE: 'setIsOnFirstSlide',
  SET_IS_ON_LAST_SLIDE: 'setIsOnLastSlide',
};

export const initialState = {
  slides: [],
  totalProducts: null,
  currentPage: 0,
  isLoading: false,
  activeIndex: null,
  isFetchingMoreItems: false,
  isOnFirstSlide: true,
  isOnLastSlide: false,
};

const handlers = {
  [ACTIONS.GET_INITIAL_SLIDES]: (state, action) => {
    const { recommendations, totalProducts } = action.payload;

    return {
      ...state,
      totalProducts,
      slides: mapRecommendationsToOfferCarousel(recommendations),
      isLoading: false,
    };
  },

  [ACTIONS.GET_SLIDES_FROM_RAW_ITEMS]: (state, action) => {
    const { rawItems } = action.payload;
    const preparedSlidesData = mapRecommendationsToOfferCarousel(rawItems);

    return { ...state, slides: preparedSlidesData, totalProducts: preparedSlidesData.length };
  },
  [ACTIONS.GET_NEXT_SLIDES]: (state, action) => {
    const { recommendations } = action.payload;
    const preparedSlidesData = mapRecommendationsToOfferCarousel(recommendations);

    return { ...state, slides: [...state.slides, ...preparedSlidesData], currentPage: state.currentPage + 1 };
  },
  [ACTIONS.SET_ACTIVE_INDEX]: (state, action) => {
    const { activeIndex } = action.payload;

    return { ...state, activeIndex };
  },
  [ACTIONS.SET_FETCH_MORE_TO_TRUE]: (state) => {
    return { ...state, isFetchingMoreItems: true };
  },
  [ACTIONS.SET_FETCH_MORE_TO_FALSE]: (state) => {
    return { ...state, isFetchingMoreItems: false };
  },
  [ACTIONS.SET_IS_ON_FIRST_SLIDE]: (state, action) => {
    const bool = action.payload;

    return { ...state, isOnFirstSlide: bool };
  },
  [ACTIONS.SET_IS_ON_LAST_SLIDE]: (state, action) => {
    const bool = action.payload;

    return { ...state, isOnLastSlide: bool };
  },
};

export const init = (isLoading) => (state) => ({
  ...state,
  isLoading,
});

export const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);
