export const mapRecommendationsToAnalyticsInput = (product) => {
  const { name, price: priceObject, id, categories, brand, catalogindex: catalogIndex, avail, index } = product;
  const seoPrice = (!!priceObject?.items?.length && priceObject.items[0].priceseo) || '';
  const categoryName = (!!categories?.length && categories[0].name) || '';
  const brandName = (!!brand?.length && brand[0].name) || '';

  return {
    availability: {
      mainVolume: avail?.quantitydc || 0,
    },
    producerName: brandName,
    categoryName,
    id,
    sapIndex: index,
    productName: name,
    price: {
      prices: [{ seoPrice }],
    },
    catalogIndex: {
      value: catalogIndex,
    },
  };
};